import { Box } from "components";
import { env } from "configs";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withTranslation,
} from "enhancers";
import { BreadcrumbsProps } from "layouts/PageContent";
import Iframe from "react-iframe";
import styled from "styled-components";
import { gql, homePath } from "utils/helper";

const FileBrowserPage = (props: any) => (
  <>
    <Iframe
      url={env.FILEBROWSER_URL}
      title="File Browser"
      width="100%"
      height="100%"
      allow="fullscreen"
    />
  </>
);

const enhancer = compose(
  withAuthorize(),
  withTranslation({
    prefix: "pages.main.fileBrowser",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;

    const { useMemo, useQuery, useParams } = hooks;

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    return {
      breadcrumbs,
    };
  })
);

export default enhancer(FileBrowserPage);
