import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";
import { ReactComponent as Settings } from "vendor/menu_icon/settings.svg";
import { ReactComponent as InsuranceIcon } from "vendor/menu_icon/insurance-icon.svg";
import { ReactComponent as RoleIcon } from "vendor/menu_icon/role-icon.svg";
import { ReactComponent as ReportMenuIcon } from "vendor/menu_icon/export-report.svg";
import { ReactComponent as EnrollmentIcon } from "vendor/menu_icon/enrollment.svg";
import { ReactComponent as EmployeeIcon } from "vendor/menu_icon/employee.svg";
import { ReactComponent as BudgetIcon } from "vendor/menu_icon/budget.svg";
import { AppColor } from "theme/app-color";
import { PERMISSIONS } from "constants/enums/permissions";
import { MaterialIcon } from "components/common/MaterialIcon";

export const MAIN_MENU: RouteType[] = [
  {
    id: "client.routes.menu.adminMenu",
    header: "client.routes.menu.menu",
    path: paths.adminsPath().toString(),
    icon: <AdminIcon />,
    permissions: [PERMISSIONS.ADMIN_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.roleMenu",
    path: paths.rolesPath().toString(),
    icon: <RoleIcon />,
    permissions: [PERMISSIONS.PERMISSION_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.report",
    path: paths.reportPath().toString(),
    icon: <ReportMenuIcon />,
    permissions: [PERMISSIONS.REPORT_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.employee",
    path: paths.employeePath().toString(),
    icon: <EmployeeIcon />,
    permissions: [PERMISSIONS.EMPLOYEE_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.budget",
    path: paths.budgetPath().toString(),
    icon: <BudgetIcon />,
    permissions: [PERMISSIONS.BUDGET_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.request",
    path: paths.requestPath().toString(),
    icon: <EnrollmentIcon />,
    permissions: [PERMISSIONS.REQUEST_MANAGEMENT_READ],
  },
  {
    id: "client.routes.menu.fileBrowser",
    path: paths.fileBrowserPath().toString(),
    icon: <MaterialIcon name="FolderOutlined" />,
    permissions: [],
  },
  // {
  //   id: "client.routes.menu.settingMenu",
  //   path: paths.settingsPath().toString(),
  //   icon: <Settings />,
  //   permissions: [PERMISSIONS.SETTING_MANAGEMENT_READ],
  // },
];
