export const AppColor = {
  "Background/Light Grey": "#F6F9FC",

  "Text/Dark Grey": "#767676",

  "Text/Black": "#212121",
  "Background/Dark Grey": "#E0E0E0",

  "Other/Success": "#4CAF4F",
  //
  "Background/Disable": "#C5C5C5",
  "Background/Background": "#EAEAEA",
  "Other/Warning": "#FF9800",
  "Other Light/Warning": "#F8E6A4",
  "Other/Info": "#0084FF",
  "Other/Danger": "#D32F2F",
  "Text/White": "#FFFFFF",
  "Text/Secondary": "#333333",
  "Text/Primary": "#000000",
  "Text/Primary Text": "#2D2D2D",
  "Text/Line": "#7C7C7C",
  "Primary/Primary Text": "#004C85",
  "Primary/Line": "#C80000",
  "Primary/Hover": "#003966",
  "Other Light/info": "#E5F6FD",
  "White / White": "#FFFFFF",
  "Primary/Primary": "#004C85",
  "Secondary/Hover": "#C80000",
  "Remove/Admin": "#FB4444",
  "Secondary  Light/Background": "#FFE4E5",

  "Text Light/Secondary": "#666666",
};
